import { usePage } from '@inertiajs/vue3'
import { computed, type DeepReadonly, type Ref } from 'vue'

import { useTimeDependent } from '@/composables/useTimeDependent'

type ReadonlyRef<T> = DeepReadonly<Ref<T>>

export function useGlobalData(): ReadonlyRef<App.Data.GlobalData> {
	const page = usePage<{
		global: App.Data.GlobalData,
	}>()

	return computed(() => page.props.global)
}

export function useUser(): ReadonlyRef<App.Data.GlobalData['user']> {
	const globalData = useGlobalData()

	return computed(() => globalData.value.user)
}

export function useAuthenticatedUser(): ReadonlyRef<NonNullable<App.Data.GlobalData['user']>> {
	const globalData = useGlobalData()

	return computed(() => {
		if (globalData.value.user === null) {
			throw new Error('user not authenticated')
		}

		return globalData.value.user
	})
}

// TODO: This should likely be controlled via Filament in the future
// That's why it's in this file as it'll come from the global data
export function useHours(): ReadonlyRef<{
	workday: {
		start: string,
		end: string,
		timezone: string,
	},
	weekend: {
		start: string,
		end: string,
		timezone: string,
	},
}> {
	const standardHours = {
		workday: {
			start: '9am',
			end: '10pm',
			timezone: 'EST',
		},

		weekend: {
			start: '10am',
			end: '9pm',
			timezone: 'EST',
		},
	}

	const newYearsHours = {
		workday: {
			start: '9am',
			end: '6pm',
			timezone: 'EST',
		},

		weekend: {
			start: '10am',
			end: '9pm',
			timezone: 'EST',
		},
	}

	return useTimeDependent([
		// Tue Dec 31 2024 23:59:59 GMT-0600 (Central Standard Time)
		[standardHours, new Date(1735711199000)],
		// Wed Jan 01 2025 23:59:59 GMT-0600 (Central Standard Time)
		[newYearsHours, new Date(1735797599000)],
	], standardHours)
}
