<script setup lang="ts">
	import { XMarkIcon } from '@heroicons/vue/24/solid'
	import { useIntersectionObserver } from '@vueuse/core'
	import { ref, useTemplateRef } from 'vue'

	import TravlFiLogo from '@/components/marketing/Logo.vue'
	import { Link } from '@/components/ui/link'
	import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTitle, SheetTrigger, type SheetVariants } from '@/components/ui/sheet'
	import { useRouterEvent } from '@/composables/useRouterEvent'

	defineProps<{
		side: SheetVariants['side'],
	}>()

	const sidebarOpen = ref(false)
	useRouterEvent('finish', () => {
		sidebarOpen.value = false
	})

	const sidebarTrigger = useTemplateRef('sidebarTrigger')
	useIntersectionObserver(sidebarTrigger, ([{ isIntersecting }]) => {
		if (!isIntersecting) {
			sidebarOpen.value = false
		}
	})
</script>

<template>
	<Sheet v-model:open="sidebarOpen">
		<SheetTrigger ref="sidebarTrigger" :class="$attrs.class">
			<slot name="trigger" />
		</SheetTrigger>

		<SheetContent :side :aria-describedby="undefined" class="w-full max-w-xs bg-gray-150 px-0 pb-12 sm:max-w-sm lg:max-w-md xl:max-w-lg">
			<SheetHeader className="bg-white py-6 -mt-6">
				<div class="flex justify-between px-4 xl:px-6">
					<SheetTitle class="text-2xl font-bold sm:text-3xl lg:text-4xl">
						<Link href="/" class="no-underline">
							<TravlFiLogo class="h-8" />
						</Link>
					</SheetTitle>
					<SheetClose>
						<button type="button" class="rounded-full px-1 py-1 hover:bg-gray-200">
							<XMarkIcon class="size-6" />
						</button>
					</SheetClose>
				</div>
			</SheetHeader>

			<div class="h-full w-full divide-y-2 divide-gray-300 overflow-y-auto border-b-2 border-t-2 border-off-white bg-gray-150 px-6 pb-4">
				<slot name="content" />
			</div>
		</SheetContent>
	</Sheet>
</template>
