<script setup lang="ts">
	import { useForm } from '@inertiajs/vue3'
	import { ChevronDownIcon } from '@radix-icons/vue'
	import { useNow } from '@vueuse/core'
	import { unref } from 'vue'

	import Logo from '@/components/marketing/Logo.vue'
	import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
	import { Button } from '@/components/ui/button'
	import { Container } from '@/components/ui/container'
	import { Input } from '@/components/ui/input'
	import { Link } from '@/components/ui/link'
	import { Separator } from '@/components/ui/separator'
	import { useHours, useUser } from '@/composables/useGlobalData'

	const user = useUser()

	const form = useForm({
		email: unref(user)?.email ?? '',
	})

	const optIn = () => {
		form.post('/opt-in', {
			preserveScroll: true,
		})
	}

	const footerLinks = [{
		title: 'Shop',
		content: [
			{ text: 'Hotspots', url: 'https://shop.travlfi.com/hotspots' },
			{ text: 'Routers', url: 'https://shop.travlfi.com/routers' },
			{ text: 'Accessories', url: 'https://shop.travlfi.com/accessories' },
			{ text: 'Range Extenders', url: 'https://shop.travlfi.com/range-extenders' },
			{ text: 'Cell Boosters', url: 'https://shop.travlfi.com/cell-boosters' },
		],
	}, {
		title: 'Resources',
		content: [
			{ text: 'Blog', url: '/blog', prefetch: true },
			{ text: 'FAQs', url: '/faqs', prefetch: true },
			{ text: 'Where To Buy', url: '/where-to-buy', prefetch: true },
			{ text: 'Affiliate Program', url: '/affiliate-program', prefetch: true },
			{ text: 'Military Program', url: '/military-discount', prefetch: true },
		],
	}, {
		title: 'Support',
		content: [
			{ text: 'Contact Us', url: '/contact', prefetch: true },
			{ text: 'About Us', url: '/about', prefetch: true },
			{ text: 'Activate Now', url: '/portal/activate' },
			{ text: 'Shipping Policy', url: 'https://shop.travlfi.com/shipping-returns/' },
			{ text: 'Return Policy', url: 'https://shop.travlfi.com/shipping-returns/' },
		],
	}]

	const legalLinks = [
		{ title: 'Privacy Policy', url: '/privacy-policy' },
		{ title: 'Terms & Conditions', url: '/tos' },
	]

	const now = useNow({ interval: 60 * 60 * 1000 })

	const hours = useHours()
</script>

<template>
	<footer>
		<form class="bg-white" @submit.prevent="optIn">
			<Container class="py-6 text-center md:py-12">
				<template v-if="form.wasSuccessful">
					<p class="text-xl font-bold text-purple md:text-2xl lg:text-4xl">You're On The List!</p>
					<p class="mt-2 md:text-lg lg:mt-3 lg:text-xl">The latest updates and special offers will be delivered straight to your inbox.</p>
				</template>

				<template v-else>
					<p class="text-balance text-lg/7 font-bold sm:text-xl/8 lg:text-2xl/9">Stay informed about software updates, potential service interruptions, and TravlFi news!</p>

					<div class="mx-auto mt-6 max-w-sm sm:flex sm:max-w-xl sm:gap-x-6 lg:max-w-3xl">
						<Input v-model="form.email" class="bg-white" type="email" placeholder="your@email.com" required />
						<Button class="mt-6 shrink-0 sm:mt-0" :disabled="form.processing">{{ form.processing ? 'Submitting...' : 'Get Started' }}</Button>
					</div>

					<p v-if="form.errors.email" class="mt-2 font-bold text-red">{{ form.errors.email }}</p>
				</template>
			</Container>
		</form>

		<div class="bg-gray-150">
			<Container class="py-6 md:py-12 lg:flex lg:justify-between lg:py-20 xl:py-24">
				<div v-for="section in footerLinks" :key="section.title" class="lg:hidden">
					<Accordion type="single" class="w-full" collapsible>
						<AccordionItem class="border-b-0" :value="section.title">
							<AccordionTrigger class="py-6">
								<template #default>
									<span class="text-lg font-extrabold md:text-xl/8">{{ section.title }}</span>
								</template>
								<template #icon>
									<ChevronDownIcon class="size-6 shrink-0 text-gray-700 transition-transform duration-200" />
								</template>
							</AccordionTrigger>
							<AccordionContent class="mt-2 flex flex-col space-y-6 pb-6">
								<Link v-for="link in section.content" :key="link.text" class="text-base font-semibold md:text-lg" :href="link.url">{{ link.text }}</Link>
							</AccordionContent>
						</AccordionItem>
					</Accordion>

					<Separator class="h-0.5 bg-gray-300" />
				</div>

				<div v-for="section in footerLinks" :key="section.title" class="hidden lg:block">
					<p class="text-2xl/9 font-extrabold">{{ section.title }}</p>
					<div class="mt-6 flex flex-col space-y-6">
						<Link v-for="link in section.content" :key="link.text" :prefetch="link.prefetch" class="text-xl/8 font-semibold" :href="link.url">{{ link.text }}</Link>
					</div>
				</div>

				<div class="mt-12 lg:mt-0">
					<Logo class="h-10" />

					<Separator class="mt-4.5 h-0.5 bg-gray-300" />

					<div class="mt-9 flex flex-col gap-y-6 sm:flex-row sm:justify-between lg:flex-col">
						<div class="font-semibold md:text-lg xl:text-xl">
							<p class="text-lg font-extrabold md:text-xl lg:text-2xl">Hours</p>
							<p class="mt-1.5 inline-block">Mon - Fri: {{ hours.workday.start }} - {{ hours.workday.end }} {{ hours.workday.timezone }}</p>
							<p class="xl:mt-2">Sat - Sun: {{ hours.weekend.start }} - {{ hours.weekend.end }} {{ hours.weekend.timezone }}</p>
						</div>
						<div class="font-semibold md:text-lg xl:text-xl">
							<p class="text-lg font-extrabold md:text-xl lg:text-2xl">Phone</p>
							<Link class="mt-1.5 inline-block" href="tel:18009606934">1-800-960-6934</Link>
						</div>
					</div>

					<div class="mt-9 flex items-center gap-x-6">
						<Link href="https://www.facebook.com/travlfi" inNewTab>
							<svg class="size-9 sm:size-12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M24 0C10.745 0 0 10.798 0 24.12c0 12.053 8.8 22.043 20.295 23.83V30.273h-6.142V23.32h6.142v-4.912c.027-4.705 1.721-6.312 1.721-6.312 2.492-3.252 6.399-3.156 6.399-3.156h6.213v6.548H30.48c-2.846-.05-3.088 2.372-3.105 2.92v4.912h6.944l-.848 6.954h-6.093v17.724C39.03 46.347 48 36.286 48 24.117 48 10.797 37.255 0 24 0Z" fill="currentColor" />
							</svg>
						</Link>

						<Link href="https://www.instagram.com/travlfi/" inNewTab>
							<svg class="size-9 sm:size-12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M28.534 12.18h-9.072a7.284 7.284 0 0 0-7.282 7.283v9.072a7.283 7.283 0 0 0 7.282 7.282h9.072c4.02 0 7.283-3.26 7.283-7.282v-9.072a7.283 7.283 0 0 0-7.283-7.283Zm-4.142 19.575a7.706 7.706 0 1 1 .001-15.412 7.706 7.706 0 0 1 0 15.412Zm7.171-13.57a1.957 1.957 0 0 1-1.959-1.958 1.96 1.96 0 1 1 1.959 1.959Z" fill="currentColor" />
								<path d="M24.394 19.416a4.633 4.633 0 0 0-4.636 4.636 4.633 4.633 0 0 0 4.636 4.636 4.635 4.635 0 1 0 0-9.272Z" fill="currentColor" />
								<path d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0Zm14.625 29.613a9.013 9.013 0 0 1-9.012 9.012H18.385a9.013 9.013 0 0 1-9.013-9.012V18.387c0-4.98 4.036-9.015 9.013-9.015h11.228c4.976 0 9.012 4.036 9.012 9.015v11.226Z" fill="currentColor" />
							</svg>
						</Link>

						<Link href="https://youtube.com/@travlfi" inNewTab>
							<svg class="size-9 sm:size-12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="m20.854 28.644 8.548-4.461-8.549-4.464v8.925Z" fill="currentColor" />
								<path d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0Zm15.694 27.787c0 3.957-1.353 7.163-5.307 7.163H14.035c-3.956 0-5.73-3.207-5.73-7.163v-7.574c0-3.957 1.608-7.163 5.561-7.163h20.685c3.956 0 5.14 3.206 5.14 7.163v7.574h.003Z" fill="currentColor" />
							</svg>
						</Link>
					</div>
				</div>
			</Container>
		</div>

		<div class="w-full bg-gray-800 text-white">
			<Container class="py-6 text-sm">
				<div class="w-full font-medium">
					<p>All plans are Pay As You Go, based on a monthly billing cycle and are subject to a one-time $30 activation fee. Coverage not available in all areas. Capable device required.</p>
					<p><sup>1</sup> TravlFi 4G Unlimited plan customers using >800GB/mo. may experience reduced speeds down to 5 Mbps. See <Link href="/tos">travlfi.com/tos</Link> for more details.</p>
					<p><sup>2</sup> 5G Unlimited plan 100 Mbps speed tier represents the maximum downlink speed but may be lower in the event of network congestion. Uplink speeds may be lower than downlink speeds.</p>
					<p><sup>3</sup> 5G Unlimited Max plan 200 Mbps speed tier represents the maximum downlink speed but may be lower in the event of network congestion. Uplink speeds may be lower than downlink speeds.</p>
					<p><sup>4</sup> TowerSync only available on select devices.</p>
				</div>

				<p class="mt-6 text-center font-medium">© {{ now.getUTCFullYear() }} TravlFi. All rights reserved.</p>

				<Separator class="mt-3 bg-white" />

				<div class="mt-3 flex flex-wrap justify-center gap-x-2 gap-y-3 text-center font-medium sm:gap-x-8 lg:gap-x-12">
					<Link v-for="link in legalLinks" :key="link.title" :href="link.url" class="underline">{{ link.title }}</Link>
				</div>
			</Container>
		</div>
	</footer>
</template>
