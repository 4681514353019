<script setup lang="ts">
	import type { ClassValue } from 'clsx'
	import { DialogContent, type DialogContentEmits, type DialogContentProps, DialogOverlay, DialogPortal, useForwardPropsEmits } from 'radix-vue'
	import { computed } from 'vue'

	import { cn } from '@/lib/utils'

	import { type SheetVariants, sheetVariants } from '.'

	interface SheetContentProps extends DialogContentProps {
		class?: ClassValue,
		side?: SheetVariants['side'],
	}

	const props = defineProps<SheetContentProps>()

	const emit = defineEmits<DialogContentEmits>()

	defineOptions({
		inheritAttrs: false,
	})

	const delegatedProps = computed(() => {
		const { class: _, side, ...delegated } = props

		return delegated
	})

	const forwarded = useForwardPropsEmits(delegatedProps, emit)
</script>

<template>
	<DialogPortal>
		<DialogOverlay class="fixed inset-0 z-50 bg-black/80 duration-[10s] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />

		<DialogContent :class="cn(sheetVariants({ side }), props.class)" v-bind="{ ...forwarded, ...$attrs }">
			<slot />
		</DialogContent>
	</DialogPortal>
</template>
