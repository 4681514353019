<script setup lang="ts">
	import { Bars3Icon, PhoneIcon, ShoppingCartIcon, UserCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
	import { usePage } from '@inertiajs/vue3'
	import { computed, ref } from 'vue'

	import AccountSidebar from '@/components/marketing/header/AccountSidebar.vue'
	import NavigationSidebar from '@/components/marketing/header/NavigationSidebar.vue'
	import TravlFiLogo from '@/components/marketing/Logo.vue'
	import { Container } from '@/components/ui/container'
	import { Link } from '@/components/ui/link'
	import { useUser } from '@/composables/useGlobalData'
	import { useTimeDependent } from '@/composables/useTimeDependent'
	import { useWindow } from '@/composables/useWindow'

	const user = useUser()

	const window = useWindow()

	const page = usePage()
	const returnUrl = computed(() => {
		if (window.value === null) {
			return null
		}

		return window.value.btoa(`${window.value.location.origin}${page.url}`)
	})

	const accountLinks = computed<Array<{
		title: string,
		text?: string,
		url: string,
		method?: 'get' | 'post',
	}>>(() => [{
		title: 'Portal',
		text: 'Activate and manage your device and data plans.',
		url: '/portal',
	}, {
		title: 'Equipment',
		text: 'View invoices and track shipments.',
		url: 'https://shop.travlfi.com/account.php',
	}, {
		title: 'Data Billing',
		text: 'Manage payment methods and history.',
		url: `/portal/billing?returnUrl=${returnUrl.value}`,
	}, {
		title: 'Log Out',
		url: '/logout',
		method: 'post',
	}])

	const navigationLinks = computed(() => [{
		label: 'Shop',
		url: 'https://shop.travlfi.com',
	}, {
		label: 'Activate Device',
		url: '/portal/activate',
	}, {
		label: 'Data Plans',
		url: '/plans',
	}, {
		label: 'Learn More',
		url: '/learn-more',
	}, {
		label: 'Support',
		sublinks: [
			{ label: 'Contact Us', url: '/contact' },
			{ label: 'About Us', url: '/about' },
			{ label: 'Activate Now', url: '/portal/activate' },
			{ label: 'Shipping Policy', url: 'https://shop.travlfi.com/shipping-returns/' },
			{ label: 'Return Policy', url: 'https://shop.travlfi.com/shipping-returns/' },
		],
	}, {
		label: 'Resources',
		sublinks: [
			{ label: 'Blog', url: '/blog' },
			{ label: 'FAQs', url: '/faqs' },
			{ label: 'Where To Buy', url: '/where-to-buy' },
			{ label: 'Affiliate Program', url: '/affiliate-program' },
			{ label: 'Military Program', url: '/military-discount' },
		],
	}])

	const miniBanner = ref(true)

	// TODO: Remove this section on February 1st
	const purpleMessaging = useTimeDependent<[string, string]>([
		// Sat Feb 01 2025 00:59:59 GMT-0500 (Eastern Standard Time)
		[['https://travlfi.com/p/xtr-pro/700-020', 'Get $100 Off XTR Pro 5G Router'], new Date(1738389599000)],
	], ['https://shop.travlfi.com/', 'Save Today With Free Shipping'])

	const closeMiniBanner = () => {
		miniBanner.value = false
	}
</script>

<template>
	<header class="sticky top-0 z-40 bg-white">
		<div v-if="miniBanner" class="relative z-40 flex w-full items-center gap-x-2 bg-purple py-0.5 text-white md:py-1">
			<Container class="flex items-center justify-between">
				<button type="button" class="invisible max-sm:hidden" @click="closeMiniBanner">
					<XMarkIcon class="size-6" />
				</button>

				<Link class="mx-auto text-pretty text-center lg:text-lg" :href="purpleMessaging[0]">{{ purpleMessaging[1] }}</Link>

				<button type="button" class="max-sm:hidden" @click="closeMiniBanner">
					<XMarkIcon class="size-6" />
				</button>
			</Container>
		</div>

		<div class="relative z-40 bg-white shadow-md">
			<Container class="flex items-center justify-between py-3 text-gray-700 sm:py-4">
				<div class="flex items-center gap-x-4 font-medium lg:gap-x-6 xl:gap-x-8">
					<NavigationSidebar class="md:hidden" :links="navigationLinks">
						<Bars3Icon class="size-6" />
					</NavigationSidebar>

					<Link href="/" class="no-underline" prefetch>
						<TravlFiLogo class="h-6 lg:h-8 xl:h-10" />
					</Link>

					<Link class="hover:underline max-md:hidden xl:text-xl/9" href="https://shop.travlfi.com/">Shop</Link>

					<Link href="/plans" prefetch class="hover:underline max-md:hidden xl:text-xl/9">Data</Link>
					<Link href="/learn-more" prefetch class="hover:underline max-md:hidden xl:text-xl/9">Learn More</Link>
					<Link href="/contact" prefetch class="hover:underline max-md:hidden xl:text-xl/9">Support</Link>
					<Link href="/activate" class="hover:underline max-md:hidden xl:text-xl/9">Activate</Link>
				</div>

				<div class="flex items-center justify-end gap-x-4 font-medium lg:gap-x-6 xl:gap-x-8">
					<Link class="flex items-center justify-center gap-x-4 md:gap-x-3" href="tel:18009606934">
						<PhoneIcon class="size-6 lg:hidden" />
						<span class="max-lg:hidden xl:text-xl/9">1-800-960-6934</span>
					</Link>

					<AccountSidebar v-if="user" class="flex items-center justify-center gap-x-3" :accountLinks>
						<UserCircleIcon class="size-6 xl:size-8" />
						<span class="max-lg:hidden xl:text-xl/9">My Account</span>
					</AccountSidebar>
					<Link v-else class="flex items-center justify-center gap-x-3" href="/portal">
						<UserCircleIcon class="size-6 xl:size-8" />
						<span class="max-lg:hidden xl:text-xl/9">Sign In</span>
					</Link>

					<Link href="https://shop.travlfi.com/cart.php">
						<ShoppingCartIcon class="size-6 xl:size-8" />
					</Link>
				</div>
			</Container>
		</div>
	</header>
</template>
