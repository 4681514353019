import type { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import type { Component, DefineComponent } from 'vue'

import Layout from '@/components/layouts/Standard.vue'
import StandardTextured from '@/components/layouts/StandardTextured.vue'

type CreateInertiaAppProps = Parameters<typeof createInertiaApp>[0]

export const resolve: CreateInertiaAppProps['resolve'] = async name => {
	const pages = import.meta.glob<DefineComponent & {
		default: {
			layout?: Component,
		},
	}>('./pages/**/*.vue')

	const page = await resolvePageComponent(`./pages/${name}.vue`, pages)

	page.default.layout ??= name.startsWith('Blog/') ? StandardTextured : Layout

	return page
}
