import { onUnmounted, shallowReadonly, type ShallowRef, shallowRef } from 'vue'

export function useTimeDependent<T>(values: Array<[value: T, ends: Date]>): Readonly<ShallowRef<T | null>>
export function useTimeDependent<T>(values: Array<[value: T, ends: Date]>, fallback: T): Readonly<ShallowRef<T>>
export function useTimeDependent<T>(values: Array<[value: T, ends: Date]>, fallback?: T): Readonly<ShallowRef<T | null>> {
	const value = shallowRef(null as T | null)
	const sortedValues = values.slice().sort(([, a], [, b]) => a.getTime() - b.getTime())

	let timeout: ReturnType<typeof setTimeout> | null = null
	onUnmounted(() => {
		if (timeout !== null) {
			clearTimeout(timeout)
		}
	})

	function calculateActive() {
		const foundValue = sortedValues.find(([, end]) => end.getTime() > Date.now())

		if (foundValue) {
			const [activeValue, end] = foundValue

			value.value = activeValue

			if (!import.meta.env.SSR) {
				timeout = setTimeout(
					calculateActive,
					end.getTime() - Date.now(),
				)
			}
		} else {
			value.value = fallback ?? null
		}
	}

	calculateActive()

	return shallowReadonly(value)
}
