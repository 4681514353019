<script setup lang="ts">
	import type { ClassValue } from 'clsx'
	import { Separator, type SeparatorProps } from 'radix-vue'
	import { computed } from 'vue'

	import { cn } from '@/lib/utils'

	const props = defineProps<SeparatorProps & { class?: ClassValue }>()

	const delegatedProps = computed(() => {
		const { class: _, ...delegated } = props

		return delegated
	})
</script>

<template>
	<Separator v-bind="delegatedProps" :class="cn('shrink-0 bg-gray-200', props.orientation === 'vertical' ? 'w-px h-full' : 'h-px w-full', $props.class)">
		<slot />
	</Separator>
</template>
